@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "dialogs.css";
@import '@Frontend/css/navigation_menu.css';
@import "navigation.css";

@layer base {
  html {
    height: 100%;
  }

  body {
  	grid-template-rows: auto auto 1fr auto;
  	min-height: 100%;
  }

  h1 {
    @apply text-2xl font-bold text-center p-4 text-white;
    background-color: rgba(0,0,0,0.6);
  }
  
  table {
    @apply table-auto;
  }

  thead {
    @apply bg-gray-200 text-left border-b border-gray-500;
  }

  th,td {
    @apply px-4 py-2;
  }

  tbody tr {
    @apply even:bg-gray-100 hover:bg-gray-300;
  }
}

@layer components {
  .form-widget {
    @apply w-full border border-brand rounded-md focus:ring-brand-d focus:border-brand-d disabled:bg-gray-100 disabled:border-gray-300;
  }

  .form-button {
    @apply bg-brand rounded-md px-4 py-2 hover:bg-brand-l transition-all disabled:bg-gray-100
  }

  .form-label {
    @apply text-sm font-bold;
  }

  header input[type=search]:focus,
  header input[type=search]:not(:placeholder-shown),
  header form:hover > input[type=search] {
    @apply bg-white border;
    width: min(60vw, 25rem);
  }

  #products {
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  }
}