@layer components {
  @keyframes dialogAnim {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes drawerAnim {
    0% {
      transform: translate(-100%, 0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: translate(0, 0);
    }
  }

  #dialog-product-detail, #dialog-cart-add {
    animation: dialogAnim 0.25s ease;
  }

  #dialog-drawer {
    min-height: 100vh;
    margin-left: 0;
    animation: drawerAnim 0.5s ease;

    nav {
      position: relative;
      min-height: 100vh;
    }

    img {
      position: absolute;
      bottom: 0;
      margin: 1rem 2rem;
    }
  }
}