@layer components {
  .dc-menu {
    padding: 0;
    margin: 0;

    li {
      padding: 0.25em 0.5em;
    }

    ul {
      border: 1px solid #fff;
      background-color: #eee;
      z-index: 99;
      width: 15em;
      margin: 0;
      box-shadow: 1px 1px 5px darkgray;

      li:hover {
        background-color: #aaa;
      }
    }

    .dc-menu-hassubmenu > a:after {
      content: '\25ba';
      float: right;
    }
  }
}

.dc-menu-drawer {
  button {
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
    color: black;
    padding: 0 1rem;
  }

  ul {
    border-bottom: 4px solid lightblue;

    li {
      padding: 0 1rem;
    }

    li:hover {
      background-color: lightblue;

      a {
        color: black;
      }
    }
  }

  & > li:focus-within {
    button {
      background-color: lightblue;
      color: black;
    }
  }
}