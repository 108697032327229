@layer components {
  .dc-menu {
    a {
      display: block;
    }

    button {
      cursor: pointer;
      width: 100%;
		
      /*right facing triangle*/
      &:after {
        content: '\25ba';
        float: right;
      }
    }
	
    ul {
      visibility: hidden;
      position: absolute;
      transition: opacity 0.5s;
      opacity: 0;
      left: 95%;
      top: 0;

      &.showonleft {
        right: 5%;
        left: auto;
      }
    }
	
    li {
      list-style-type: none;
      display: block;
      position: relative;
		
      /*fix for safari*/ 
      &:focus {
        outline: none;
      }
    }
	
    & > li {
      display: inline-block;
		
      & > button:after {
        content: '\25bc';
        padding-left: 0.25em;
      }


      /*on focus of button show submenu*/
      &:focus-within {
        & > ul {
          visibility: visible;
          opacity: 1;
          left: auto;
          top: auto;
        }
      }
    }
	
    ul li:hover > ul {
      visibility: visible;
      opacity: 1;
    }
  }
}

.dc-menu-drawer {
  button {
    width: 100%;
    text-align: left;
		
    /*right facing triangle*/
    &:after {
      content: '\25ba';
      float: right;
    }
  }
	
  ul {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: 0.5s;
  }

  & > li:focus-within {
    ul {
      height: inherit;
      opacity: 1;
    }
  }
}